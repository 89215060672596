
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import BaseFormElement from '../components/BaseFormElement.vue';
import ProjectForm from '../components/ProjectForm.vue';

import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';

import { Project } from '../api/projects/project.model';
import { Company } from '../api/companies/company.model';
import { Invite } from '../api/users/invite.model';
import { Role } from '../api/auth/role.enum';
import { Watch } from 'vue-property-decorator';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '../shared/types/form-element.class';
import { User } from '../api/users/user.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseTable, BaseHeader, ProjectForm, BaseFormElement },
})
export default class Projects extends Vue {
  $refs!: Vue['$refs'] & {
    detailModal: BModal;
    createModal: BModal;
  };
  isLoading = false;
  tabIndex = 0;
  columns: Column[] = [
    new Column('name', 'Name'),
    new Column('company.name', 'Company', undefined, false),
    new Column('email', 'Email'),
    new Column('description', 'Description'),
  ];

  projectForDetail: Project = new Project();
  newProject: Project = new Project();

  inviteAdmin = {
    invite: false,
    email: '',
  };
  adminEmailElement = new FormElement(
    'email',
    'Project admin e-mail',
    new FormElementOptions(),
    'required|email',
  );
  inviteAdminCheckboxElement = new FormElement(
    'invite',
    'Invite a project admin when creating the project?',
    new FormElementOptions(),
    '',
    FormElementType.CHECKBOX,
  );

  filter: Filter = new Filter(undefined, undefined, ['company']);

  @usersModule.Getter('loggedInUser')
  currentUser!: User;

  @projectsModule.Getter('all')
  projects!: Project[];

  @projectsModule.Getter('pagination')
  pagination!: PaginateResult<Project>;

  @projectsModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Project[]>;

  @projectsModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  @projectsModule.Action('create')
  create!: (project: Project) => Promise<Project>;

  @projectsModule.Action('update')
  update!: (project: Project) => Promise<Project>;

  @usersModule.Action('inviteUsers')
  inviteUsers!: (invite: Invite) => Promise<void>;

  @Watch('inviteAdmin.invite')
  onInviteChanged(newValue: boolean) {
    if (newValue) {
      this.inviteAdmin.email = this.newProject.email;
    }
  }

  openCalendar(project: Project) {
    const route = this.$router.resolve({
      name: 'ProjectCalendar',
      params: { id: project._id },
    });
    window.open(route.href, '_blank');
  }

  async doCreate(project: Project) {
    const createdProject = await this.create(project);
    this.newProject = new Project();
    this.doFetchAll();
    if (!this.inviteAdmin.invite) {
      return;
    }
    const invite = new Invite(
      [this.inviteAdmin.email],
      Role.ProjectAdmin,
      [createdProject._id],
      this.currentUser.company,
    );
    await this.inviteUsers(invite);
  }

  async doUpdate(project: Project) {
    await this.update(project);
    this.projectForDetail = new Project();
    this.doFetchAll();
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  openCreateModal() {
    this.newProject.company = { _id: this.currentUser.company };
    this.$refs.createModal.show();
  }

  openDetail(project: Project) {
    this.$router.push({ name: 'Project', params: { id: project._id } });
  }

  async doDelete(project: Project) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${project.name}?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );

    if (!result) {
      return;
    }

    await this.delete(project._id);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
    const openCreate = this.$route.query.openCreate;
    if (openCreate) {
      this.openCreateModal();
    }
  }
}
