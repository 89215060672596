




import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import BaseCalendar from '../components/BaseCalendar.vue';

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseCalendar } })
export default class ProjectCalendar extends Vue {
  get id() {
    return this.$route.params.id;
  }
}
