





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { ProjectSettings } from '../api/projects/project-settings.model';
import { namespace } from 'vuex-class';

import { Category } from '../api/categories/category.model';
import { Filter } from '../shared/types/filter.class';

const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class ProjectSettingsForm extends Vue {
  @Prop({
    default: () => {
      return new ProjectSettings();
    },
  })
  projectSettings!: ProjectSettings;

  formElements: FormElement[][] = [];

  @categoriesModule.Action('fetchAll')
  fetchAllCategories!: (filter?: Filter) => Promise<Category[]>;

  async created() {
    const cats = await this.fetchAllCategories();
    if (!this.projectSettings.settings) {
      this.projectSettings.settings = {};
    }
    this.projectSettings.settings.suspendAfter =
      this.projectSettings.settings.suspendAfter || -1;
    this.formElements.push([
      new FormElement(
        'settings.suspendAfter',
        'Suspend user after (days) default:',
        new FormElementOptions(),
        '',
        FormElementType.NUMBER,
      ),
    ]);
    for (const cat of cats) {
      this.projectSettings.settings[`noShow-${cat._id}`] =
        this.projectSettings.settings[`noShow-${cat._id}`] || 0;
      this.projectSettings.settings[`minDuration-${cat._id}`] =
        this.projectSettings.settings[`minDuration-${cat._id}`] || 0;
      this.projectSettings.settings[`fineAfterInMinutes-${cat._id}`] =
        this.projectSettings.settings[`fineAfterInMinutes-${cat._id}`] || 0;
      this.projectSettings.settings[`finePerHour-${cat._id}`] =
        this.projectSettings.settings[`finePerHour-${cat._id}`] || 0;
      this.projectSettings.settings[`autoEnd-${cat._id}`] =
        this.projectSettings.settings[`autoEnd-${cat._id}`] || true;

      this.formElements.push([
        new FormElement(
          `settings.noShow-${cat._id}`,
          `${cat.title} no show duration:`,
          new FormElementOptions(),
        ),
        new FormElement(
          `settings.minDuration-${cat._id}`,
          `${cat.title} no minimum booking duration:`,
          new FormElementOptions(),
        ),
        new FormElement(
          `settings.fineAfterInMinutes-${cat._id}`,
          `${cat.title} fine user after being late for (mins):`,
          new FormElementOptions(),
        ),
        new FormElement(
          `settings.finePerHour-${cat._id}`,
          `${cat.title} late fee per hour:`,
          new FormElementOptions(),
        ),
        new FormElement(
          `settings.autoEnd-${cat._id}`,
          `${cat.title} automatically end bookings:`,
          new FormElementOptions(),
          undefined,
          FormElementType.CHECKBOX,
        ),
      ]);
    }
  }
}
