





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { Project } from '../api/projects/project.model';
import { Company } from '../api/companies/company.model';
import { namespace } from 'vuex-class';
import { Filter } from '../shared/types/filter.class';

const companiesModule = namespace('companies');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class ProjectForm extends Vue {
  @Prop()
  project!: Project;

  formElements: FormElement[][] = [
    [
      new FormElement(
        'showBookingsPublic',
        'Show the calendar publically?',
        new FormElementOptions(),
        'required',
        FormElementType.CHECKBOX,
      ),
    ],
    [
      new FormElement(
        this.calendarUrl,
        'Calendar URL',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
        'required',
      ),
    ],
    [
      new FormElement(
        'name',
        'Project name',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'email',
        'Support email',
        new FormElementOptions(),
        'required|email',
      ),
    ],
    [
      new FormElement(
        'description',
        'Description',
        new FormElementOptions(),
        '',
        FormElementType.TEXTAREA,
      ),
    ],
    [new FormElement('vat', 'VAT', new FormElementOptions(), 'required')],
    [
      new FormElement(
        'address.streetAndNumber',
        'Street and number',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'address.zipCode',
        'Zip Code',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'address.city',
        'City',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'address.countryCode',
        'Country',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'image',
        'Logo/Image',
        new FormElementOptions(),
        '',
        FormElementType.IMAGE,
      ),
    ],
    [
      new FormElement('formTitle', 'Survey Title'),
      new FormElement('formUrl', 'Survey URL'),
    ],
    [
      new FormElement(
        'surveyDescription',
        'Survey Description',
        new FormElementOptions(),
        '',
        FormElementType.TEXTAREA,
      ),
    ],
  ];

  @companiesModule.Getter('all')
  companies!: Company[];

  @companiesModule.Action('fetchAll')
  fetchAllCompanies!: (filter?: Filter) => Promise<Company[]>;

  calendarUrl(project: Project) {
    const route = this.$router.resolve({
      name: 'PublicProjectCalendar',
      params: { token: project.token },
    });
    return `${window.location.origin}${route.href}`;
  }

  async created() {
    if (this.$can('read', 'Company')) {
      await this.fetchAllCompanies();
      this.formElements.unshift([
        new FormElement(
          'company',
          'Company',
          new FormElementOptions(this.companies, 'name', '_id'),
          '',
          FormElementType.SELECT,
        ),
      ]);
    }
  }
}
