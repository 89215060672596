
























import { Booking } from '@/api/bookings/booking.model';
import { CastType, Filter, Operator } from '@/shared/types/filter.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';

import BaseHeader from '../components/BaseHeader.vue';
import BookingsCalendar from '../components/BookingsCalendar.vue';
import { Asset } from '@/api/assets/asset.model';
import { BookingState } from '@/api/bookings/booking-state.enum';

const bookingsModule = namespace('bookings');
const assetsModule = namespace('assets');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BookingsCalendar, BaseHeader } })
export default class BaseCalendar extends Vue {
  isLoading = false;
  filter: Filter = new Filter();
  selectedAsset: string = null;

  startDate = moment()
    .startOf('week')
    .toDate();
  endDate = moment()
    .endOf('week')
    .toDate();

  @Prop({
    default: 'Bookings',
  })
  title!: string;

  @Prop()
  project?: string;

  @Prop()
  token?: string;

  @Prop()
  asset?: string;

  @Prop({
    default: true,
  })
  showAssetFilter!: boolean;

  @bookingsModule.Getter('all')
  bookings!: Booking[];

  @bookingsModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Booking[]>;

  @bookingsModule.Action('fetchAllForProjectByToken')
  fetchAllForProjectByToken!: (payload: {
    token: string;
    filter?: Filter;
  }) => Promise<Booking[]>;

  @assetsModule.Getter('all')
  assets!: Asset[];

  @assetsModule.Action('fetchAll')
  fetchAllAssets!: (filter?: Filter) => Promise<Asset[]>;

  @assetsModule.Action('fetchAllForProjectByToken')
  fetchAllAssetsForProjectByToken!: (token: string) => Promise<Asset[]>;

  @Watch('selectedAsset')
  onAssetSelected() {
    this.doFetchAll();
  }

  onDatesChanged(dateRange: { start: Date; end: Date }) {
    if (
      this.startDate.getTime() === dateRange.start.getTime() &&
      this.endDate.getTime() === dateRange.end.getTime()
    ) {
      return;
    }
    this.startDate = dateRange.start;
    this.endDate = dateRange.end;
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;

    this.filter.replaceFilter(
      'date',
      this.startDate.toISOString(),
      Operator.GreaterThanOrEqual,
    );
    this.filter.addFilter(
      'date',
      this.endDate.toISOString(),
      Operator.LessThanOrEqual,
    );

    this.filter.replaceFilter('state', [
      BookingState.Pending,
      BookingState.Ended,
      BookingState.Started,
    ]);
    if (this.project) {
      this.filter.replaceFilter('project', this.project);
    }
    if (this.selectedAsset) {
      this.filter.replaceFilter('asset', this.selectedAsset);
    }

    await this.fetch();
    this.isLoading = false;
  }

  async fetch(): Promise<Booking[]> {
    if (this.project) {
      return this.fetchAll(this.filter);
    }
    return this.fetchAllForProjectByToken({
      filter: this.filter,
      token: this.token,
    });
  }

  async doFetchAllAssets() {
    if (this.project) {
      const assetFilter = new Filter();
      if (this.project) {
        assetFilter.replaceFilter(
          'project',
          this.project,
          Operator.Equal,
          CastType.ObjectId,
        );
      }
      return this.fetchAllAssets(assetFilter);
    }

    return this.fetchAllAssetsForProjectByToken(this.token);
  }

  created() {
    this.doFetchAllAssets();
  }
}
