








import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import FullCalendar, { CalendarOptions } from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Booking } from '@/api/bookings/booking.model';
import moment from 'moment';
import { BookingState } from '@/api/bookings/booking-state.enum';

const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { FullCalendar } })
export default class BookingsCalendar extends Vue {
  $refs!: Vue['$refs'] & {
    fullCalendar: any;
  };

  showDayView = false;
  calendarOptions: CalendarOptions = {
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: 'timeGridWeek',
    nowIndicator: true,
    height: '90vh',
    locale: 'nl',
    firstDay: 1,
    events: [],
    datesSet: this.handleDateSelect,
    dayHeaderContent: this.formatHeaderContent,
  };
  calendarOptionsMobile: CalendarOptions = {
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: 'timeGridDay',
    nowIndicator: true,
    height: '90vh',
    locale: 'nl',
    firstDay: 1,
    events: [],
    datesSet: this.handleDateSelect,
    dayHeaderContent: this.formatHeaderContent,
  };

  bookingStateColors = {
    [BookingState.Pending]: '',
    [BookingState.Started]: '#36af47',
    [BookingState.Ended]: '#919191',
  };

  @Prop({
    type: Array,
  })
  bookings!: Booking[];

  get desktopElement() {
    return document.getElementById('desktopCalendarView');
  }

  get desktopElementVisible() {
    return document.getElementById('desktopCalendarView') !== null;
  }

  @Watch('bookings')
  onBookingsChanged() {
    this.calendarOptions.events = this.mappedEvents();
    this.calendarOptionsMobile.events = this.mappedEvents();
  }

  @Emit('dates-changed')
  handleDateSelect(info) {
    return { start: info.start, end: info.end };
  }

  switchView(view: string) {
    this.$refs.fullCalendar.getApi().changeView(view);
  }

  mappedEvents() {
    return this.bookings.map((b: Booking): any => {
      return {
        id: b._id,
        title: this.eventTitle(b),
        start: new Date(b.start),
        end:
          b.state === BookingState.Ended
            ? new Date(b.stoppedOn)
            : new Date(b.end),
        display: 'block',
        backgroundColor: this.bookingStateColors[b.state],
      };
    });
  }

  formatHeaderContent(dayInfo: any) {
    return moment(dayInfo.date).format('ddd DD/MM');
  }

  formatEventTime(event) {
    console.log(event);
    return '';
  }

  eventTitle(b: Booking): string {
    let title = `${b.asset?.name}`;
    if (b.user) {
      title += ` - ${b.user?.firstName} ${b.user?.lastName}`;
    } else {
      title += ` - N/A`;
    }
    return title;
  }

  mounted() {
    const el = document.getElementById('desktopCalendarView');
    const style = window.getComputedStyle(el);
    if (style.display === 'block') {
      this.switchView('timeGridWeek');
    } else {
      this.switchView('timeGridDay');
    }
  }
}
