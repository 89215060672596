


































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import BaseFormElement from '../components/BaseFormElement.vue';
import ProjectForm from '../components/ProjectForm.vue';
import ProjectSettingsForm from '../components/ProjectSettingsForm.vue';

import { Project } from '../api/projects/project.model';
import { User } from '../api/users/user.class';
import { ProjectSettings } from '../api/projects/project-settings.model';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';

const appModule = namespace('app');
const projectsModule = namespace('projects');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseTable,
    BaseHeader,
    ProjectForm,
    BaseFormElement,
    ProjectSettingsForm,
  },
})
export default class ProjectDetail extends Vue {
  tabIndex = 0;

  projectForDetail: Project = new Project();
  projectSettings: ProjectSettings = new ProjectSettings();
  originalSettings: any = {};

  @usersModule.Getter('loggedInUser')
  currentUser!: User;

  @projectsModule.Getter('current')
  currentProject!: Project;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @projectsModule.Action('update')
  update!: (project: Project) => Promise<Project>;

  @projectsModule.Action('fetchById')
  fetchById!: (id: string) => Promise<Project>;

  @projectsModule.Action('fetchSettingsByProject')
  fetchSettingsByProject!: (projectId: string) => Promise<ProjectSettings>;

  @projectsModule.Action('updateSettingsByProject')
  updateSettingsByProject!: (
    settings: ProjectSettings,
  ) => Promise<ProjectSettings>;

  async doUpdate(project: Project, projectSettings: ProjectSettings) {
    projectSettings.updateAssets = false;
    if (!_.isEqual(this.projectSettings.settings, this.originalSettings)) {
      projectSettings.updateAssets = await this.$bvModal.msgBoxConfirm(
        `Do you want to update all attached assets?`,
        {
          title: 'Update assets',
          okTitle: 'Yes',
          okVariant: 'primary',
          centered: true,
          cancelTitle: 'No',
        },
      );
    }
    await this.update(project);
    await this.updateSettingsByProject(projectSettings);
    this.addToast(new Toast('Project & settings updated', ToastType.SUCCESS));
  }

  async created() {
    this.projectForDetail = await this.fetchById(this.$route.params.id);
    this.projectSettings = await this.fetchSettingsByProject(
      this.$route.params.id,
    );
    if (!this.projectSettings) {
      this.projectSettings = new ProjectSettings(this.$route.params.id);
    }
    this.originalSettings = { ...this.projectSettings.settings };
  }
}
