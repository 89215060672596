




import { Project } from '@/api/projects/project.model';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import BaseCalendar from '../components/BaseCalendarPublic.vue';

const projectsModule = namespace('projects');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseCalendar } })
export default class PublicProjectCalendar extends Vue {
  @projectsModule.Getter('current')
  project!: Project;

  @projectsModule.Action('fetchByToken')
  fetchByToken!: (token: string) => Promise<Project>;

  get token() {
    return this.$route.params.token;
  }

  get id() {
    return this.project?._id;
  }

  async created() {
    // await this.fetchByToken(this.token);
  }
}
